




























import { Component, Mixins } from 'vue-property-decorator';
import { TestimonialFilter } from 'client-website-ts-library/filters';
import { View } from 'client-website-ts-library/plugins';

import TestimonialCards from '@/components/Testimonials/TestimonialCards.vue';
import ItemCycler from '@/components/Cycler/ItemCycler.vue';
import { ItemCyclerEmbeddableSlide, ItemCyclerSlide } from '@/app_code/ItemCycler';
import { Testimonial, WebsiteLevel } from 'client-website-ts-library/types';

@Component({
  components: {
    TestimonialCards,
    ItemCycler,
  },
})
export default class Testimonials extends Mixins(View) {
    public filter: TestimonialFilter = new TestimonialFilter({
      SearchLevel: WebsiteLevel.Office,
      SearchGuids: [
        '89d8223b-9ff5-4ab3-8ff6-a712d78a05b3',
      ],
    });

    private cyclerItems: ItemCyclerSlide[] = [];

    handleGotTestimonials(testimonials: Testimonial[]) {
      this.cyclerItems = testimonials.filter((t) => t.Video !== null).map((t) => new ItemCyclerEmbeddableSlide(t.Video!));
    }
}
